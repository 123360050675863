@mixin x-date-rotor-theme($theme, $typography) {
  .x-date-rotor {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 12px;

    box-sizing: border-box;
    border: 1px solid var(--x-foreground-divider);
    border-radius: 22px;

    .select-day,
    .select-month,
    .select-year {
      height: 44px;
      line-height: 44px;
    }

    .select-day {
      width: 100px;
    }
    .select-month {
      width: 150px;
    }
    .select-year {
      width: 100px;
    }

    .color-default {
      color: var();
    }
    .color-primary {
    }
    .color-accent {
    }
  }
}
