@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-dashboard-operation-history-theme($theme, $typography) {
  $colors: map.get($theme, 'colors');

  .operation-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 480px;

    .operation-item {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 8px;
      padding: 3px;
      border-radius: 4px;

      &-icon {
        width: 24px;
        flex: 0 0 auto;
      }

      &-title {
        flex: 1 1 auto;
      }

      &-status {
        flex: 0 1 auto;
        opacity: 0.7;
      }

      &-line {
        width: 100%;
        flex: 1 1 auto;
        padding-left: 32px;
        opacity: 0.7;
      }

      &.ok {
        .operation-item-icon {
          color: mat.get-color-from-palette(map.get($colors, 'green'), 500);
        }
      }
      &.loading {
        .operation-item-icon {
          color: mat.get-color-from-palette(map.get($colors, 'yellow'), 500);
        }
      }
      &.uninitialized {
        .operation-item-icon {
          color: mat.get-color-from-palette(map.get($colors, 'gray'), 500);
        }
      }
      &.cancelled {
        .operation-item-icon {
          color: mat.get-color-from-palette(map.get($colors, 'red'), 500);
        }
      }
      &.error {
        .operation-item-icon {
          color: mat.get-color-from-palette(map.get($colors, 'red'), 500);
        }
      }
    }
    .operation-error-log {
      display: flex;
      flex-direction: column;
      padding-left: 36px;

      &-code {
        cursor: pointer;
        padding-bottom: 4px;
      }

      &-detail {
        font-family: monospace;
        overflow: hidden;
      }
    }
  }
}
