@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-address-pill-theme($theme, $typography) {
  $colors: map.get($theme, 'colors');

  .x-address-pill {
    .pill-body .pill-line {
      font-size: 11px;
      line-height: 13px;
    }
  }
}
