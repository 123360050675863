@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-bulk-order-entry-pill-theme($theme, $typography) {
  $colors: map.get($theme, 'colors');

  .x-bulk-order-entry-pill {
    &.in-sync {
      background-color: mat.get-color-from-palette(map.get($colors, 'light-green'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'light-green'), '500-contrast');
    }
    &:not(.in-sync) {
      background-color: mat.get-color-from-palette(map.get($colors, 'yellow'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'yellow'), '500-contrast');
    }
    &.error {
      background-color: mat.get-color-from-palette(map.get($colors, 'red'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'red'), '500-contrast');
    }
  }
}
