@use '@angular/material' as mat;
@mixin x-pill-theme($theme, $typography) {
  $primary: map-get($theme, 'primary');
  $foreground: map-get($theme, 'foreground');
  .x-pill {
    display: inline-block;
    line-height: 24px;
    background-color: mat.get-color-from-palette($primary, 50);
    border-radius: 12px;
    padding: 0 6px;
    font-size: 12px;
    margin-right: 6px;
    white-space: nowrap;
    position: relative;
    min-width: 12px;
    text-align: center;

    .pill-content {
      // text-transform: uppercase;
      font-weight: bold;
      display: inline;
    }

    .pill-remove-button {
      background-color: transparent;
      border: none;
      display: inline;
      text-align: right;
      padding: 0;
      line-height: 24px;
      padding-left: 4px;
      cursor: pointer;
    }

    .pill-icon {
      margin-right: 6px;
    }

    &.color-primary {
      background-color: mat.get-color-from-palette(map-get($theme, primary));
      color: mat.get-color-from-palette(map-get($theme, primary), '500-contrast');
      .pill-remove-button {
        color: mat.get-color-from-palette(map-get($theme, primary), 50);
      }
    }
    &.color-accent {
      background-color: mat.get-color-from-palette(map-get($theme, accent));
      color: mat.get-color-from-palette(map-get($theme, accent), '500-contrast');
      .pill-remove-button {
        color: mat.get-color-from-palette(map-get($theme, accent), 50);
      }
    }
    &.color-warn {
      background-color: mat.get-color-from-palette(map-get($theme, warn));
      color: mat.get-color-from-palette(map-get($theme, warn), '500-contrast');
      .pill-remove-button {
        color: mat.get-color-from-palette(map-get($theme, warn), 50);
      }
    }

    @each $color, $palette in map-get($theme, colors) {
      &.#{'color-' + $color} {
        background-color: mat.get-color-from-palette($palette);
        color: mat.get-color-from-palette($palette, '500-contrast');
        .pill-remove-button {
          color: mat.get-color-from-palette($palette, 300);
        }
      }
    }

    &.color-white {
      background-color: white;
    }

    &.mat-menu-trigger,
    &.mat-mdc-menu-trigger {
      cursor: pointer;
    }
  }

  .x-pill-group {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 6px;
  }

  .x-pill-base {
    border-radius: 15px;
    padding: 2px 12px 2px 0;
    display: inline-flex;
    align-items: center;
    @include mat.typography-level($typography, 'body-2');
    transition: background-color 600ms ease-in;
    background-color: var(--x-color, white);
    color: var(--x-color-contrast, black);

    &.mat-menu-trigger {
      cursor: pointer;
    }

    .pill-icon {
      width: 36px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .pill-body {
      margin-left: 8px;
    }

    .pill-body:nth-child(2) {
      margin-left: 0;
    }

    .pill-label {
      @include mat.typography-level($typography, 'body-1');
    }

    .pill-line {
      @include mat.typography-level($typography, 'body-2');
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .pill-line-icon {
        display: inline-block;
        width: 16px;
        margin-right: 2px;
      }

      &:hover {
        overflow: visible;
      }
    }
  }
}
