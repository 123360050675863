@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-channel-pill-theme($theme, $typography) {
  .x-channel-pill {
    display: inline-flex;
    flex-flow: row;
    .channel-dot {
      width: 13px;
      height: 13px;
      border-radius: 50%;
    }
  }
}
