@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-button-extensions($theme, $typography) {
  $primary: map-get($theme, 'primary');
  $background: map-get($theme, 'background');

  $divider-color: mat.get-color-from-palette($primary, 300);
  // $background-color: mat.get-color-from-palette($primary, 0); //50
  $blue-gray-500: mat.get-color-from-palette($background, blue-gray-500);

  .mat-mdc-menu-panel {
    min-height: 52px !important;
  }

  /**
    * Make menu items more dense
    */
  // button.mat-menu-item,
  // a.mat-menu-item {
  //   height: 36px;
  //   line-height: 36px;
  // }

  // .mat-menu-panel .mat-menu-content:not(:empty) {
  //   padding-top: 0;
  //   padding-bottom: 0;
  // }

  /**
  * Allow using mat-subheader with mat-menu
  */
  .mat-menu-panel .mat-menu-content [mat-subheader] {
    @include mat.typography-level($typography, 'body-2');
    color: mat.get-color-from-palette(map-get($theme, foreground), 'secondary-text');
    padding: 0 16px;
    line-height: 36px;
  }

  /**
  * Add some space between tab label and icon
  */
  .mat-mdc-tab-labels .ng-fa-icon {
    margin: 6px;
  }

  .mat-mdc-menu-item .mat-mdc-menu-item-text .ng-fa-icon {
    margin-right: 6px;
  }

  $primary: map.get($theme, primary);

  .mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
    border-radius: 0;
    .mat-button-toggle-label-content {
      line-height: 32px;
    }
    .mat-button-toggle-button {
      fa-icon {
        display: block;
        font-size: 14px;
      }
    }
    .mat-button-toggle-checked {
      background-color: mat.get-color-from-palette($primary, 500);
      color: white;
    }
  }
}
