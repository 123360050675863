@use '@angular/material' as mat;

@mixin x-data-autocomplete-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);

  .x-data-autocomplete {
    .mat-chip-list .mat-chip-list-wrapper {
      gap: 6px;
      padding-bottom: 4px;
    }

    .mat-mdc-standard-chip {
      .mdc-evolution-chip__cell--primary,
      .mdc-evolution-chip__action--primary,
      .mat-mdc-chip-action-label {
        overflow: unset;
      }
    }

    .mdc-evolution-chip__cell--primary {
      overflow-x: hidden !important;
    }

    .input {
      &,
      &::placeholder {
        opacity: 1;
        width: 100%;
        padding: 8px 0;
        margin-top: 4px;
      }
    }

    &.disabled .mat-mdc-chip-input {
      display: none;
    }
  }

  .x-data-autocomplete-panel {
    .mat-option {
      height: auto;
      min-height: 48px;
    }
  }
}
