@use '@angular/material' as mat;
@mixin x-dialog-layout-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, 'background');
  $divider-color: mat.get-color-from-palette($foreground, divider);
  $background-color: mat.get-color-from-palette($background, card);

  .x-dialog-layout {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    max-height: 100%;
    overflow: hidden;

    background-color: $background-color;

    .x-dialog-toolbar {
      flex: 0 0 54px;
    }

    .dialog-panel-container {
      flex: 1 1 auto;
      display: flex;
      flex-flow: row nowrap;
      overflow: hidden;

      .x-dialog-panel {
        border-right: 1px solid $divider-color;
      }

      .mat-tab-group {
        flex: 1 1 auto;
      }

      .mat-tab-body-wrapper {
        flex: 1 1 auto;
      }

      .x-dialog-panel:last-child {
        border-right: none;
      }
    }
  }
}
