@use '@angular/material' as mat;

@mixin x-address-autocomplete-theme($theme, $typography) {
  .x-address-suggestions-overlay {
    display: block;
    max-height: 350px;
    @include media-breakpoint-down(lg) {
      max-height: 250px;
    }
    width: 100%;
    border-radius: 0 0 5px 5px;
    background-color: white;
    box-shadow: 0 3px 2px rgba(black, 0.1);
    position: relative;
    overflow: hidden;

    .suggestions {
      position: relative;
      overflow: auto;
      height: 100%;

      .suggestion {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        gap: 10px;
        &:hover {
          cursor: pointer;
          background: mat.get-color-from-palette(map-get($theme, accent), 100);
        }

        .icon-left {
          position: relative;
          top: -7px;
        }

        .icon-right {
          margin-left: auto;
        }

        .labels {
          max-width: 88%;

          @include media-breakpoint-down(lg) {
            max-width: 80%;
          }

          .label-wrap {
            display: flex;
            gap: 3px;
            align-items: center;

            .label {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &.main {
                color: mat.get-color-from-palette(map-get($theme, primary), 500);
                @include mat.typography-level($typography, body-1);
              }

              &.sub {
                @include mat.typography-level($typography, display-3);
              }
            }
            .size {
              @include mat.typography-level($typography, display-1);
              white-space: nowrap;
            }
          }
        }

        &.selected {
          background: mat.get-color-from-palette(map-get($theme, accent), 200);
          padding: 10px;
          position: sticky;
          top: 0;
          z-index: 1;
          .label {
            font-style: italic;
          }
        }
      }
    }

    /*saved addresses*/
    .saved-addresses {
    }

    .loader {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}
