@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-order-incident-overlay($theme, $typography) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  $colors: map.get($theme, 'colors');

  x-order-incident-overlay {
    display: flex;

    .trigger {
      display: flex;
      position: relative;
      align-items: center;
      border: 1px solid mat.get-color-from-palette(map.get($colors, 'amber'), 500);
      border-radius: 0.2em;
      line-height: 16px;
      gap: 12px;
      padding: 4px 12px;
      cursor: pointer;

      .count {
        position: absolute;
        color: mat.get-color-from-palette(map.get($colors, 'amber'), '500-contrast');
        background: mat.get-color-from-palette(map.get($colors, 'amber'), 500);
        border: 1px solid mat.get-color-from-palette(map.get($colors, 'amber'), 500);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 10px;
        font-weight: bold;
        line-height: 1;
        height: 18px;
        width: 18px;
        bottom: 0;
        right: 0;
        transform: translate(20%, 20%);
        text-align: center;
      }
    }
  }
}
