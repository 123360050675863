@import './components/product-detail/product-detail.theme';
@import './components/product-form/product-form.theme';
@import './components/taxon-form/taxon-form.theme';
@import './components/taxon-tree/taxon-tree.theme';
@import './components/taxon-index/taxon-index.theme';
@import './components//product-associations-form/product-associations-form.theme';

@mixin x-products-theme($theme, $typography) {
  @include x-product-form-theme($theme, $typography);
  @include x-taxon-form-theme($theme, $typography);
  @include x-taxon-tree-theme($theme, $typography);
  @include x-taxon-index-theme($theme, $typography);
  @include x-product-detail-theme($theme, $typography);
  @include x-product-associations-form-theme($theme, $typography);
}

.col-row-form {
  display: flex;
  overflow-x: auto;
  padding: 0 0.5rem;
  .col-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 -0.5rem;
    .col {
      display: flex;
      flex-direction: column;
      padding: 0 0.5rem;
    }
  }
}
