@use '@angular/material' as mat;

@mixin x-dialog-panel-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);

  $header-footer-background: mat.get-color-from-palette($background, card);
  $panel-background: mat.get-color-from-palette($background, background);
  $side-panel-background: mat.get-color-from-palette($background, app-bar);

  $divider-color: mat.get-color-from-palette($foreground, divider);
  $title-color: mat.get-color-from-palette($primary, 500);

  .x-dialog-panel {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    overflow: auto;

    background-color: $panel-background;

    &.bg-transparent {
      background: transparent;
      .panel-content {
        background: transparent;
      }
    }

    &.side {
      flex: 0 1 300px;
      .panel-content {
        background-color: $side-panel-background;
      }
    }

    &.side-x {
      flex: 0 1 200px;
      .panel-content {
        background-color: $side-panel-background;
      }
    }

    &.side-m {
      flex: 0 1 400px;
      .panel-content {
        background-color: $side-panel-background;
      }
    }

    &.side-l {
      flex: 0 1 500px;
      .panel-content {
        background-color: $side-panel-background;
      }
    }

    &.half {
      flex: 0 1 50%;
    }

    &.full {
      flex: 1 1 700px;
    }

    .panel-header {
      display: flex;
      flex-flow: column nowrap;
      background-color: $header-footer-background;

      &:not(:empty) {
        border-bottom: 1px solid $divider-color;
      }

      [xPanelHeader] {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        padding: 6px 12px;
        min-height: 43px;
        margin-top: 0;
        margin-bottom: 0;
        @include mat.typography-level($typography, 'body-2');
      }
    }

    .panel-footer {
      display: flex;
      flex-flow: column nowrap;
      background-color: $header-footer-background;

      &:not(:empty) {
        border-top: 1px solid $divider-color;
      }

      [xPanelFooter] {
      }
    }

    .panel-content {
      background-color: $panel-background;
      flex: 1 1 auto;
      overflow: auto;
      .mat-tab-header {
        background-color: $header-footer-background;
      }

      [xPanelTab] {
        height: 100%;
        .mat-tab-label {
          min-width: 60px;
          height: 55px;
        }
      }
    }

    .panel-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background-color: rgba($panel-background, 0.5);
    }

    .panel-error {
      padding: 12px;
      display: flex;
      align-items: center;
      &-icon {
        margin-right: 12px;
      }
      &-title {
        @include mat.typography-level($typography, body-2);
      }
      &-message {
        @include mat.typography-level($typography, body-1);
      }
      &-heading {
      }
      &-content {
      }
    }

    // nested panels
    .x-dialog-panel {
      .panel-header {
        &:not(:empty) {
          border-top: 1px solid $divider-color;
        }
      }
    }
  }
}
