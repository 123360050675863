@use '@angular/material' as mat;
@use 'sass:map';
@mixin x-admin-home-theme($theme, $typography) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, 'background');

  .x-admin-home {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100vh;
    overflow: auto;

    .app-toolbar {
      position: fixed;
      z-index: 4;
      height: 56px;
      padding: 0 6px;
      gap: 12px;
      border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);

      color: mat.get-color-from-palette($primary, 900);

      .app-toolbar-title {
        @include mat.typography-level($typography, main-toolbar-title);
      }
    }

    .app-fixing-spacer {
      height: 56px;
      flex: 0 0 56px;
    }

    .app-toolbar-spacer {
      flex: 1 1 auto;
    }

    .app-tools {
      display: flex;
      gap: 12px;
      align-items: stretch;

      .top-bar-action {
        cursor: pointer;
        border: 1px solid mat.get-color-from-palette($primary, 50);
        border-radius: 0.2em;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 12px;
      }
    }

    .app-sidenav-container {
      flex: 1 1 auto;
      .mat-drawer-backdrop {
        --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.1);
      }
    }

    .app-sidenav-content {
      flex-flow: column nowrap;
    }

    .app-sidenav-nav {
      width: 280px;
      overflow: hidden;

      .app-sidenav-menu {
        height: 100%;
        overflow: hidden;
      }
    }

    .ai-sidenav {
      width: 400px;

      .ai-sidenav-chat {
        height: 100%;
        overflow: hidden;
      }
    }
  }
}
