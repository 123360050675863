@use '@angular/material' as mat;
@mixin xd-table-theme($theme, $typography) {
  .xd-table {
    width: 100%;
    border: 1px solid #e0e9f3;

    th {
      text-align: left;
      padding: 3px 6px;
    }

    td {
      text-align: left;
      padding: 3px 6px;
    }

    // .mat-mdc-icon-button,
    // .mat-mdc-button-touch-target,
    // .mat-mdc-button-ripple,
    // .table-row-action {
    //   width: 16px !important;
    //   height: 16px !important;

    //   padding: 0px !important;

    //   display: flex;
    //   flex-direction: column;
    // }
  }
}
