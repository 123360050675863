@mixin x-inventory-theme($theme, $typography) {
  $primary: map-get($theme, 'primary');

  .custom-calendar {
    .fc-day-event-custom {
      border-radius: 8px;
      font-weight: bold;
      text-align: center;
      border: none;
    }

    .fc-button-primary {
      background-color: mat.get-color-from-palette($primary, 500);
      border-color: mat.get-color-from-palette($primary, 500);
    }

    .fc-button-primary:hover {
      background-color: mat.get-color-from-palette($primary, 400);
      border-color: mat.get-color-from-palette($primary, 400);
    }

    .fc-button-primary:disabled {
      background-color: mat.get-color-from-palette($primary, 100);
      border-color: mat.get-color-from-palette($primary, 100);
    }

    .fc-daygrid-day.fc-day-today {
      background-color: mat.get-color-from-palette($primary, 100);
    }
  }
}
