@use '@angular/material' as mat;

@mixin x-data-table-states-theme($theme, $typography) {
  $foreground: map-get($theme, 'foreground');
  $primary: map-get($theme, 'primary');

  .x-data-table-states {
    .mat-mdc-input-element:disabled {
      color: inherit;
      font-style: italic;
      border: none;
      background-color: transparent;
    }

    .state-header {
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 12px 16px;
      position: sticky;
      border-bottom: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }

    .state-header-title {
      flex: 1 1 auto;
      font-size: 14px;
      font-weight: 600;
    }

    .state-save-label {
      font-size: 11px;
    }

    .mdc-list-item.active {
      background-color: mat.get-color-from-palette($primary, 50);
    }
  }
}
