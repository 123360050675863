@import './components/shipping-slot-calendar/shipping-slot-calendar.theme';
// @import './components/shipment-quick-view/shipment-quick-view.theme';

@mixin x-logistics-theme($theme, $typography) {
  .x-shipment-state-NEW {
    --x-color: var(--x-blue-gray-500);
    --x-color-contrast: var(--x-blue-gray-500-contrast);
  }
  .x-shipment-state-WAYBILL_REQUIRED {
    --x-color: var(--x-orange-500);
    --x-color-contrast: var(--x-orange-500-contrast);
  }
  .x-shipment-state-RESERVED {
    --x-color: var(--x-yellow-500);
    --x-color-contrast: var(--x-yellow-500-contrast);
  }
  .x-shipment-state-PENDING {
    --x-color: var(--x-blue-500);
    --x-color-contrast: var(--x-blue-500-contrast);
  }
  .x-shipment-state-SHIPPED {
    --x-color: var(--x-light-green-500);
    --x-color-contrast: var(--x-light-green-500-contrast);
  }
  .x-shipment-state-CANCELLED {
    --x-color: var(--x-red-500);
    --x-color-contrast: var(--x-red-500-contrast);
  }
  .x-shipment-state-DELIVERED {
    --x-color: var(--x-green-500);
    --x-color-contrast: var(--x-green-500-contrast);
  }

  @include x-shipping-slot-calendar-theme($theme, $typography);
  // @include x-shipment-quick-view-theme($theme, $typography);
}
