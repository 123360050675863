@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-notification-pill-theme($theme, $typography) {
  $colors: map.get($theme, 'colors');

  .x-notification-pill {
    &.status-SENT {
      background-color: mat.get-color-from-palette(map.get($colors, 'green'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'green'), '500-contrast');
    }
    &.status-UNSUPPORTED {
      background-color: mat.get-color-from-palette(map.get($colors, 'yellow'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'yellow'), '500-contrast');
    }
    &.status-FAILED {
      background-color: mat.get-color-from-palette(map.get($colors, 'red'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'red'), '500-contrast');
    }
  }
}
