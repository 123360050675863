@use '@angular/material' as mat;

@mixin x-dashboard-toolbar-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $background: map-get($theme, 'background');

  $divider-color: mat.get-color-from-palette($primary, 100);
  $title-color: mat.get-color-from-palette($primary, 800);
  $subtitle-color: mat.get-color-from-palette($primary, 800, 0.75);

  .x-dashboard-toolbar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    padding-left: 12px;
    padding-right: 12px;

    height: 64px;

    border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
    gap: 10px;
    overflow: visible;

    .toolbar-header {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      gap: 12px;
    }

    .toolbar-icon {
      color: $title-color;
      svg {
        width: 0.8em !important;
      }
    }

    .toolbar-title-divider {
      height: 40px;
      width: 1px;
      border-left: 1px solid $divider-color;
    }

    .toolbar-title {
      color: $title-color;
      margin: 0;
      flex: 0 1 auto;
      @include mat.typography-level($typography, toolbar-title);
      text-transform: uppercase;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .toolbar-subtitle {
      margin: 0;
      flex: 0 1 auto;
      @include mat.typography-level($typography, toolbar-subtitle);
      color: $subtitle-color;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .toolbar-actions {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: end;
      gap: 12px;
      flex: 1 1 auto;
    }

    [xToolbarAction].mat-mdc-select {
      width: auto;
      flex: 0 1 auto;
      min-width: 100px;
    }

    [xToolbarAction].mat-date-range-input {
      width: auto;
      min-width: 200px;
    }

    .mat-select {
      width: 150px;
    }
  }
}
