@use '@angular/material' as mat;

@mixin x-grid-controls-theme($theme, $typography) {
  $foreground: map-get($theme, 'foreground');

  .x-grid-controls {
    align-items: center;
    justify-content: flex-end;
    flex-flow: row wrap;
    @include mat.typography-level($typography, 'caption');
    color: map-get($foreground, 'secondary-text');

    .control {
      // same height as paginator
      height: 61px;
      display: flex;
      align-items: center;
      margin-right: 12px;
    }

    .mat-icon-button {
      color: map-get($foreground, 'secondary-text');
    }
    .sort-label {
      color: map-get($foreground, 'hint-text');
      margin-right: 6px;
    }

    .sort-select {
      width: 100px;
      margin-right: 6px;
    }

    .column-select {
      width: 40px;
    }

    .mat-select {
      @include mat.typography-level($typography, 'caption');
      .mat-select-value {
        color: map-get($foreground, 'secondary-text');
      }
    }
  }
}
