@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-order-pill-theme($theme, $typography) {
  $colors: map.get($theme, 'colors');

  .x-order-pill {
    &.state-CART {
      background-color: mat.get-color-from-palette(map.get($colors, 'blue-gray'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'blue-gray'), '500-contrast');
    }
    &.state-ENQUIRY {
      background-color: mat.get-color-from-palette(map.get($colors, 'yellow'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'yellow'), '500-contrast');
    }
    &.state-QUOTED {
      background-color: mat.get-color-from-palette(map.get($colors, 'yellow'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'yellow'), '500-contrast');
    }
    &.state-PROCESSING {
      background-color: mat.get-color-from-palette(map.get($colors, 'orange'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'orange'), '500-contrast');
    }
    &.state-CANCELLED {
      background-color: mat.get-color-from-palette(map.get($colors, 'red'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'red'), '500-contrast');
    }
    &.state-COMPLETED {
      background-color: mat.get-color-from-palette(map.get($colors, 'green'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'green'), '500-contrast');
    }
    &.state-EXPIRED {
      background-color: mat.get-color-from-palette(map.get($colors, 'gray'), 300);
      color: mat.get-color-from-palette(map.get($colors, 'gray'), '500-contrast');
    }
  }
}
