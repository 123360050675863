@use '@angular/material' as mat;

@mixin x-data-table-controls-theme($theme, $typography) {
  $foreground: map-get($theme, 'foreground');
  $background: map-get($theme, 'background');

  .x-data-table-controls {
    align-items: center;
    justify-content: flex-end;
    flex-flow: row wrap;
    @include mat.typography-level($typography, 'caption');
    color: map-get($foreground, 'secondary-text');
    background-color: map-get($background, 'card');

    .note {
      flex-grow: 1;
      margin-left: 12px;
    }

    .control {
      // same height as paginator
      height: 61px;
      display: flex;
      align-items: center;
      margin-right: 12px;
    }

    .mat-icon-button {
      color: map-get($foreground, 'secondary-text');
    }
    .sort-label {
      color: map-get($foreground, 'hint-text');
      margin-right: 6px;
    }

    .sort-select {
      width: 100px;
      margin-right: 6px;
    }

    .column-select {
      width: auto;
      min-width: 180px;
      @include mat.typography-level($typography, 'caption');
      display: flex;
      align-items: center;
      .ng-fa-icon {
        font-size: 16px;
      }
      mat-select-trigger {
        display: flex;
        align-items: center;
      }
    }

    .mat-select {
      @include mat.typography-level($typography, 'caption');
      .mat-select-value {
        color: map-get($foreground, 'secondary-text');
      }
    }

    .mat-paginator-page-size-select {
      margin-top: 0;
    }
  }
}
