@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-media-index-theme($theme, $typography) {
  $colors: map.get($theme, 'colors');

  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .x-media-index {
    .media-breadcrumb {
      .media-breadcrum-separator {
        display: inline-block;
        font-size: 12px;
      }

      .last {
        margin-left: 8px;
      }
    }

    .media-grid {
      padding: 1rem;
      display: grid;
      grid-template-columns: repeat(auto-fit, 200px);
      gap: 1rem;
    }

    .media-folder,
    .media-item,
    .media-loadmore,
    .media-upload {
      background-color: transparent;
      box-sizing: border-box;

      border-radius: 9px;
      overflow: hidden;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }

    .media-item {
      border: 1px solid mat.get-color-from-palette($accent, 100);
      img {
        max-width: 100%;
      }
    }

    .media-folder,
    .media-loadmore,
    .media-upload {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 4px;
      min-width: auto;
      max-width: 300px;
      margin: auto;
      aspect-ratio: auto 1 / 1;

      background: mat.get-color-from-palette($accent, 100);

      color: mat.get-color-from-palette($primary, 500);

      fa-icon {
        font-size: 3rem;
        color: mat.get-color-from-palette($accent, 800);
        margin-bottom: 0.5rem;
      }
    }

    .media-loadmore,
    .media-upload {
      overflow: hidden;
    }

    .media-loadmore {
      border: none;
      padding: 0;
      font-size: inherit;

      &[disabled] {
        cursor: default;
        pointer-events: none;
      }

      fa-icon {
        color: mat.get-color-from-palette($primary, 500);
      }
    }

    .media-upload {
      cursor: default;
      pointer-events: none;
    }
  }
}
