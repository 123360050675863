@use '@angular/material' as mat;
@mixin x-action-row-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);

  $divider-color: mat.get-color-from-palette($foreground, divider);
  $title-color: mat.get-color-from-palette($primary, 500);

  .x-action-row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 6px 12px;
    min-height: 43px;
    gap: 12px;

    .action-row-spacer {
      flex: 1 1 auto;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
      margin-bottom: 0;
    }

    [xActionStart] {
    }

    [xActionEnd] {
    }

    [xActionFlex] {
      flex: 1 1 auto;
    }
  }
}
