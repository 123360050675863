@import './components/order-map/_order-map.theme';
@import './components/order-item-manager/_order-item-manager.theme';
@import './components/order-incident-form/_order-incident-form.theme';
@import './components/order-incident-overlay/_order-incident-overlay.theme';

@mixin x-orders-theme($theme, $typography) {
  $warn: map-get($theme, warn);

  @include x-order-map-theme($theme, $typography);
  @include x-order-item-manager-theme($theme, $typography);
  @include x-order-incident-form-theme($theme, $typography);
  @include x-order-incident-overlay($theme, $typography);

  .color-warn {
    color: mat.get-color-from-palette($warn, 400);
  }
}
