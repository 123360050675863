@use '@angular/material' as mat;
@mixin x-panel-section-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $header-footer-background: mat.get-color-from-palette($background, card);
  $panel-background: mat.get-color-from-palette($background, background);
  $side-panel-background: mat.get-color-from-palette($background, app-bar);
  $divider-color: mat.get-color-from-palette($foreground, divider);
  $title-color: mat.get-color-from-palette($primary, 500);

  .x-panel-section {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    overflow: auto;
    background-color: $panel-background;
    &:not(:first-of-type) {
      border-top: 1px solid $divider-color;
    }

    .panel-header {
      display: flex;
      flex-flow: column nowrap;
      background-color: $header-footer-background;

      &:not(:empty) {
        border-bottom: 1px solid $divider-color;
      }

      [xPanelHeader] {
      }
    }

    .panel-content {
      background-color: $panel-background;
      flex: 1 1 auto;
      overflow: auto;
    }

    .panel-footer {
      display: flex;
      flex-flow: column nowrap;
      background-color: $header-footer-background;

      &:not(:empty) {
        border-top: 1px solid $divider-color;
      }

      [xPanelFooter] {
      }
    }
  }
}
