@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-fullcalendar($theme, $typography) {
  .fc-theme-standard {
    font-size: 10px;
    .fc-toolbar-title {
      font-size: 1.4em;
    }
    .fc-daygrid-day-number {
      font-size: 0.8em;
    }

    .fc-button {
      text-transform: uppercase;
      @include mat.typography-level($typography, button);
      letter-spacing: 0.1em;
    }
  }
}
