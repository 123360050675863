//
@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-order-item-manager-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);

  .x-order-item-manager {
    .x-search-field {
      max-width: unset;
      margin: auto;
      padding: 0.4em 0.8em;
      height: 25px;

      &.disabled {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
      }
    }

    .order-items-table-container {
      max-width: 100%;
      overflow: auto;
      .order-items-table {
        width: 100%;
        min-width: 600px;
        td {
          vertical-align: middle;
        }
        th {
          line-height: 1.2;
        }

        .product-name {
          display: inline-block;
          min-width: 80px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1;
          -webkit-box-orient: vertical;

          .mat-subtitle-2 {
            line-height: 1.2;
          }
        }

        .actions-col {
          display: flex;
          height: 48px;
        }
      }
    }
  }

  .product-variant-autocomplete-item {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 0;

    .text {
      margin-right: auto;
      display: flex;
      flex-direction: column;

      .name {
        font-size: 12px;
      }

      .sku {
        font-weight: normal;
      }
    }

    .price {
      margin-left: 0;
    }

    .icons {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 4px;

      fa-icon {
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
