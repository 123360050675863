@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-product-associations-form-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $colors: map.get($theme, 'colors');

  .xd-list-item {
    &.selectable:not(.selected):hover {
      background: #f0f0f0;
      cursor: pointer;
    }
    &.selected {
      background: mat.get-color-from-palette($primary, 500);
      color: white;
    }
  }

  .no-association {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .details-column {
    max-width: 30dvw;
    min-width: 240px;
    width: 100%;
    white-space: normal;
    & > .xd-list-item .list-item-body {
      padding: 4px 0;
    }
  }

  .channel-select {
    min-width: 140px;
  }

  .product-dropzone {
    position: relative;
    background: #f0f0f0;
    min-height: 40px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  .association-panel {
    .panel-content {
      display: flex;
      flex-flow: column;
    }
  }

  .product-associated-products-table {
    tr.recommendation {
      td {
        background-color: #f0f0f0;
      }
    }
    x-media-thumbnail {
      background-color: transparent;
    }
  }
}
