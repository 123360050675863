@use '@angular/material' as mat;

@mixin x-variables($theme, $typography) {
  html {
    @each $color, $palette in map-get($theme, colors) {
      @each $grade, $value in $palette {
        @if type-of($value) == color {
          #{'--x-' + $color + '-' + $grade}: $value;
        }
      }
    }
    @each $grade, $value in map-get($theme, primary) {
      @if type-of($value) == color {
        #{'--x-primary-' + $grade}: $value;
      }
    }
    @each $grade, $value in map-get($theme, accent) {
      @if type-of($value) == color {
        #{'--x-accent-' + $grade}: $value;
      }
    }
    @each $grade, $value in map-get($theme, warn) {
      @if type-of($value) == color {
        #{'--x-warn-' + $grade}: $value;
      }
    }
    @each $grade, $value in map-get($theme, background) {
      @if type-of($value) == color {
        #{'--x-background-' + $grade}: $value;
      }
    }
    @each $grade, $value in map-get($theme, foreground) {
      @if type-of($value) == color {
        #{'--x-foreground-' + $grade}: $value;
      }
    }
  }
}
