// $grid-gutter-width: 12px;

@import 'bootstrap/scss/bootstrap-grid';

@mixin bootstrap($theme, $typography) {
  $foreground: map-get($theme, 'foreground');

  $divider-color: map-get($foreground, 'divider');

  .container,
  .container-fluid {
    box-sizing: border-box;
  }

  .row.col-dividers > * {
    border-right: 1px solid $divider-color;
  }

  .row.col-dividers > *:last-child {
    border-right: none;
  }
}
