@use '@angular/material' as mat;

@import 'components/dashboard-layout-simplex/dashboard-layout-simplex.theme';
@import 'components/dashboard-toolbar/dashboard-toolbar.theme';
@import 'components/dashboard-panel/dashboard-panel.theme';
@import 'components/dashboard-errors/dashboard-errors.theme';
@import 'components/content/content.theme';
@import 'components/action-row/action-row.theme';
@import 'components/panel-section/panel-section.theme';
@import 'components/spinner/spinner.theme';
@import 'components/pill/pill.theme';
@import 'components/dashboard-operation-history/dashboard-operation-history.theme';
@import 'components/table/table.theme';
@import 'components/list/list.theme';
@import 'components/expand-item/expand-item.theme';
@import 'components/clip/clip.theme';
@import 'components/shape/shape.theme';
@import 'components/card/card.theme';
@import 'components/date-rotor/date-rotor.theme';

@mixin x-dashboard-core-theme($theme, $typography) {
  @each $color, $palette in map-get($theme, colors) {
    .#{'xd-background-' + $color} {
      background-color: mat.get-color-from-palette($palette);
    }
    .#{'xd-text-' + $color} {
      color: mat.get-color-from-palette($palette);
    }
    .#{'xd-color-' + $color} {
      background-color: mat.get-color-from-palette($palette);
      color: mat.get-color-from-palette($palette, '500-contrast');
    }
  }

  @include x-dashboard-layout-simplex-theme($theme, $typography);
  @include x-dashboard-toolbar-theme($theme, $typography);
  @include x-dashboard-panel-theme($theme, $typography);
  @include x-dashboard-errors-theme($theme, $typography);
  @include x-action-row-theme($theme, $typography);
  @include x-content-theme($theme, $typography);
  @include x-panel-section-theme($theme, $typography);
  @include x-spinner-theme($theme, $typography);
  @include x-pill-theme($theme, $typography);
  @include x-dashboard-operation-history-theme($theme, $typography);
  @include xd-table-theme($theme, $typography);
  @include xd-list-theme($theme, $typography);
  @include xd-expand-item-theme($theme, $typography);
  @include x-clip-theme($theme, $typography);
  @include x-shape-theme($theme, $typography);
  @include x-card-theme($theme, $typography);
  @include x-date-rotor-theme($theme, $typography);
}
