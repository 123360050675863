@use '@angular/material' as mat;

@mixin x-grid-table-theme($theme, $typography) {
  .x-grid-table {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);

    overflow: hidden;

    .selection-overlay {
      background-color: mat.get-color-from-palette($accent, 500);
      color: mat.get-color-from-palette($accent, 500-contrast);
      padding: 12px 24px;

      .selection-overlay-checkbox {
        margin-left: 16px;
        .mat-checkbox-inner-container {
          margin-right: 18px;
        }
      }

      .selection-overlay-drag-handle {
        cursor: grab;
      }

      .selection-overlay-actions {
        button {
          margin-left: 10px;
        }
      }
    }

    .table-container {
      overflow: auto;
      height: 100%;
    }

    tbody {
      vertical-align: middle;
    }

    th.mat-header-cell {
      padding-right: 6px;
    }

    td.mat-cell {
      padding-right: 6px;
    }

    .cdk-column-_action,
    .cdk-column-_action:last-of-type {
      padding-left: 6px;
      padding-right: 6px;
      border-left: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }

    .mat-cell.cdk-column-_select {
      width: 60px;
    }
    .mat-cell.cdk-column-_drag {
      cursor: grab;
      width: 20px;
      text-align: center;
    }

    .selection-box {
      cursor: pointer;
      position: relative;
      width: 48px;
      height: 48px;
      .mat-spinner {
        position: absolute;
        top: 0;
        left: 0;
        margin: 4px;
        pointer-events: none;
      }
      .mat-checkbox {
        .mat-checkbox-layout {
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .mat-checkbox-inner-container {
        }
      }
    }

    th,
    td {
      white-space: nowrap;
    }
  }
}
