@use '@angular/material' as mat;
@mixin x-shape-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);

  .xd-shape {
    display: inline-block;
    width: 1em;
    height: 1em;
    background-color: var(--x-color);
    color: var(--x-color-contrast);
    vertical-align: text-top;

    &.shape-circle {
      border-radius: 50%;
    }
  }
}
