@use '@angular/material' as mat;

@mixin x-current-user-theme($theme, $typography) {
  $primary: map-get($theme, primary);

  .x-current-user {
    display: flex;

    .user-pill {
      display: flex;
      align-items: center;
      border: 1px solid mat.get-color-from-palette($primary, 50);
      border-radius: 0.2em;
      line-height: 16px;
      gap: 12px;
      padding: 4px 12px;
      cursor: pointer;
    }

    .user-name {
      font-size: 12px;
      font-weight: 500;
    }

    .user-email {
      font-weight: 300;
      font-size: 11px;
    }
  }
}
