@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-order-map-theme($theme, $typography) {
  $colors: map.get($theme, 'colors');

  .x-order-map {
    position: relative;
    flex-grow: 1;

    ::ng-deep .gm-style div {
      border: none !important;
    }

    .map-table-controls-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 16px;
    }

    #sync-bounds-button {
      position: absolute;
      display: block;
      top: 16px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 100;
    }

    .info-window-inner {
      width: 100%;
      overflow-y: auto;
      padding-top: 12px;

      .order-info-window-row {
        display: flex;
        align-items: center;
        padding: 12px;
      }
    }
  }
}
