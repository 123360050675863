@use '@angular/material' as mat;

@mixin x-cron-theme($theme, $typography) {
  .x-cron-input {
    .cron-label {
      cursor: pointer;
    }
  }

  .x-cron-overlay-backdrop {
    background-color: transparent;
  }

  .x-cron-input-overlay {
    border-radius: 4px;
    background-color: white;
    display: flex;
    flex-flow: row wrap;
    @include mat.elevation(2);
    padding: 12px;
    gap: 12px;
    font-size: 12px;
    align-items: center;

    .mat-mdc-select {
      font-size: 12px;
      width: 100px;
    }

    .hint {
      color: #333;
    }
  }
  .x-cron-input-toggle {
    display: inline-flex;
    width: 30px;
    height: 30px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }
}
