@use '@angular/material' as mat;

@mixin x-form-panel-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);

  $divider-color: mat.get-color-from-palette($foreground, divider);
  $title-color: mat.get-color-from-palette($primary, 500);

  .x-form-panel {
    font-size: 14px;
    padding-top: 1em;

    .mat-form-field {
      // margin-bottom: 6px;
    }

    .mat-slide-toggle,
    .mat-checkbox {
      margin-top: 6px;
      margin-bottom: 6px;
      @include mat.typography-level($typography, caption);
      align-self: center;
    }

    [xFormHeader] {
      margin-top: 24px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      @include mat.typography-level($typography, form-heading);
    }

    [xFormSubheader] {
      @include mat.typography-level($typography, form-subheading);
      min-height: 40px;
    }

    [xFormLabel] {
      @include mat.typography-level($typography, caption);
      color: mat.get-color-from-palette($foreground, secondary-text, 0.6);
      min-height: 24px;
    }

    [xFormHint] {
      @include mat.typography-level($typography, form-hint);
      color: mat.get-color-from-palette($foreground, secondary-text, 0.6);
      min-height: 24px;
    }

    [xInputDense] {
      font-size: 12px;
    }
  }
}
