@use '@angular/material' as mat;
@mixin x-clip-theme($theme, $typography) {
  .x-clip {
    $clip-icon-size: 20px;

    cursor: copy;
    position: relative;

    &:hover::after {
      content: '📋';
      position: absolute;
      display: inline-block;
      width: $clip-icon-size;
      height: $clip-icon-size;
      border-radius: $clip-icon-size / 2;
      background-color: white;
      color: black;
      top: -2px;
      right: -$clip-icon-size;
      text-align: center;
      line-height: $clip-icon-size;
      font-size: 14px;
    }

    &.copied:hover::after {
      content: '✔';
      color: green;
    }
  }
}
