@use '@angular/material' as mat;

@mixin x-shipping-slot-calendar-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $warn: map-get($theme, warn);

  .x-shipping-slot-calendar {
    display: block;
    height: 100%;
    .fc .fc-daygrid-day-frame {
      //background-color: mat.get-color-from-palette($background, 'status-bar');
    }

    .fc-daygrid-dot-event:hover {
      background-color: mat.get-color-from-palette($foreground, divider);
    }

    .shipping-slot-event {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;

      &.disabled {
        opacity: 0.5;

        .event-box {
          &.selected {
            background-color: rgba(mat.get-color-from-palette($foreground, divider), 0.9);
          }
        }
      }

      .xd-shape {
        margin: 4px;
        flex: 0 0 auto;
      }
    }

    .event-box {
      flex: 1 1 auto;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      border-radius: 3px;
      border: 1px solid mat.get-color-from-palette($foreground, divider);
      overflow: hidden;
      padding: 4px;
      position: relative;

      &.selected {
        background-color: rgba(mat.get-color-from-palette($foreground, divider), 0.5);
      }
    }

    .time {
      flex: 1 1 auto;
    }

    .state-counts {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background-color: mat.get-color-from-palette($foreground, divider);
      display: flex;
    }

    .bar {
      height: 6px;
      background-color: var(--x-color);
    }

    .availability {
      flex: 0 1 auto;
    }

    .cancelled {
      flex: 1 1 auto;
      color: mat.get-color-from-palette($warn, 500);
    }

    .method {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      color: mat.get-color-from-palette($foreground, hint-text);
    }

    .override {
      font-weight: bold;
    }
  }
}
