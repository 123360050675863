@import 'components/form-panel/form-panel.theme';
@import 'components/dashboard-form-layout/dashboard-form-layout.theme';
@import 'components/search-field/search-field.theme';
@import 'components/form-status-label/form-status-label.theme';
@import 'components/data-select/data-select.theme';
@import 'components/data-autocomplete/data-autocomplete.theme';

@mixin x-dashboard-form-theme($theme, $typography) {
  // add some spacing for icons used with matPrefix
  .mat-form-field-prefix > .ng-fa-icon {
    margin-right: 12px;
  }

  // add spacing for icons used with mat-label
  .mat-form-field-label .ng-fa-icon {
    margin-right: 6px;
  }

  .x-filter-field-base {
    display: block;
  }

  @include x-dashboard-form-layout-theme($theme, $typography);
  @include x-form-panel-theme($theme, $typography);
  @include x-search-field-theme($theme, $typography);
  @include x-form-status-label-theme($theme, $typography);
  @include x-data-select-theme($theme, $typography);
  @include x-data-autocomplete-theme($theme, $typography);
}
