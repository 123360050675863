@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-user-payment-method-pill-theme($theme, $typography) {
  $colors: map.get($theme, 'colors');

  .x-user-payment-method-pill {
    &.state-ACTIVE {
      background-color: mat.get-color-from-palette(map.get($colors, 'green'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'green'), '500-contrast');
    }
    &.state-CANCELLED {
      background-color: mat.get-color-from-palette(map.get($colors, 'red'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'red'), '500-contrast');
    }
    &.state-PROCESSING {
      background-color: mat.get-color-from-palette(map.get($colors, 'yellow'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'yellow'), '500-contrast');
    }
    &.state-FAILED {
      background-color: mat.get-color-from-palette(map.get($colors, 'red'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'red'), '500-contrast');
    }

    &.expired {
      background-color: mat.get-color-from-palette(map.get($colors, 'red'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'red'), '500-contrast');
    }

    &.archived {
      opacity: 0.7;
    }

    .pill-body .pill-line {
      font-size: 11px;
      line-height: 13px;
    }
  }
}
