@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-fulfilment-pill-theme($theme, $typography) {
  $colors: map.get($theme, 'colors');

  .x-fulfilment-pill {
    &.state-NEW {
      background-color: mat.get-color-from-palette(map.get($colors, 'blue-gray'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'blue-gray'), '500-contrast');
    }
    &.state-RESERVED {
      background-color: mat.get-color-from-palette(map.get($colors, 'yellow'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'yellow'), '500-contrast');
    }
    &.state-ALLOCATED {
      background-color: mat.get-color-from-palette(map.get($colors, 'orange'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'orange'), '500-contrast');
    }
    &.state-PACKED {
      background-color: mat.get-color-from-palette(map.get($colors, 'blue'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'blue'), '500-contrast');
    }
    &.state-UNALLOCATED {
      background-color: mat.get-color-from-palette(map.get($colors, 'pink'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'pink'), '500-contrast');
    }
    &.state-FULFILLED {
      background-color: mat.get-color-from-palette(map.get($colors, 'green'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'green'), '500-contrast');
    }
    &.state-CANCELLED {
      background-color: mat.get-color-from-palette(map.get($colors, 'red'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'red'), '500-contrast');
    }
  }
}
