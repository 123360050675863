@use '@angular/material' as mat;
@mixin x-icon-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .ng-fa-icon {
    &.mat-card-avatar {
      font-size: 28px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.icon-color-primary {
      color: mat.get-color-from-palette($primary);
    }
    &.icon-color-accent {
      color: mat.get-color-from-palette($accent);
    }
    &.icon-color-warn {
      color: mat.get-color-from-palette($warn);
    }
    &.icon-color-current {
      color: currentColor;
    }
  }

  .mat-button,
  .mat-flat-button {
    .mat-button-wrapper .ng-fa-icon {
      vertical-align: 0;
      font-size: 14px;
    }
  }

  .mat-icon-button .mat-button-wrapper .ng-fa-icon {
    vertical-align: middle;
    font-size: 16px;
  }

  .mat-menu-item .ng-fa-icon {
    margin-right: 12px;
  }
}
