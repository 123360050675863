@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-subscriber-table-theme($theme, $typography) {
  $colors: map.get($theme, 'colors');

  .x-subscriber-table {
    .interval-pill {
      border-radius: 15px;
      padding: 4px 8px;

      span {
        font-weight: bold;
      }
    }
    .interval-ACTIVE {
      background-color: mat.get-color-from-palette(map.get($colors, 'green'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'green'), '500-contrast');
    }
    .interval-PENDING {
      background-color: mat.get-color-from-palette(map.get($colors, 'yellow'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'yellow'), '500-contrast');
    }
    .interval-EXPIRED {
      background-color: mat.get-color-from-palette(map.get($colors, 'red'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'red'), '500-contrast');
    }
  }
}
