@use '@angular/material' as mat;

@mixin x-form-status-label-theme($theme, $typography) {
  $warn: map-get($theme, 'warn');
  $primary: map-get($theme, 'primary');
  $accent: map-get($theme, 'accent');
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');

  .x-form-status-label {
    display: inline-block;
    background-color: red;
    border-radius: 12px;
    line-height: 24px;
    padding: 0 12px;
    color: mat.get-color-from-palette($foreground, 'text');
    background-color: mat.get-color-from-palette($background, selected-button);
    @include mat.typography-level($typography, 'body-2');

    &.status-invalid {
      color: mat.get-color-from-palette($warn, '500-contrast');
      background-color: mat.get-color-from-palette($warn, 500);
    }

    &.status-valid {
      color: mat.get-color-from-palette($primary, '500-contrast');
      background-color: mat.get-color-from-palette($primary, 500);
    }
  }
}
