@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-shipment-pill-theme($theme, $typography) {
  $colors: map.get($theme, 'colors');

  .method-dot {
    margin-right: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}
