@use '@angular/material' as mat;

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;500;700&family=Nunito:wght@400;500;600;700&display=swap');

@function app-typography-config() {
  $header-font: 'Cormorant Garamond', serif;
  $content-font: 'Nunito Sans', 'Helvetica Neue', sans-serif;
  $monospace-font: monospace;
  $font-family: $content-font;

  $app-typography: (
    // NEW
    headline-1: mat.define-typography-level(112px, 112px, 400, $letter-spacing: -0.05em),
    headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
    headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
    headline-4: mat.define-typography-level(34px, 40px, 400, $font-family: $header-font),
    headline-5: mat.define-typography-level(24px, 32px, 700, $font-family: $content-font),
    headline-6: mat.define-typography-level(18px, 34px, 700, $font-family: $content-font),
    subtitle-1: mat.define-typography-level(14px, 34px, 600, $font-family: $content-font),
    subtitle-2: mat.define-typography-level(12px, 24px, 700, $font-family: $content-font),
    body-1: mat.define-typography-level(12px, 18px, 700, $font-family: $content-font),
    body-2: mat.define-typography-level(12px, 16px, 400, $font-family: $content-font),
    overline: mat.define-typography-level(12px, 32px, 600),
    caption: mat.define-typography-level(10px, 19px, 400, $font-family: $content-font),
    button: mat.define-typography-level(12px, 14px, 600, $font-family: $content-font),
    // Line-height must be unit-less fraction of the font-size.
    input: mat.define-typography-level(inherit, 1.125, 400, $font-family: $content-font),
    // CUSTOM
    display-1: mat.define-typography-level(11px, 15px, 400, $content-font, 0.05em),
    display-3: mat.define-typography-level(11px, 15px, 400, $content-font, 0.05em),
    main-toolbar-title:
      mat.define-typography-level(
        24px,
        32px,
        700,
        $font-family: $header-font,
        $letter-spacing: 0.2em
      ),
    toolbar-title:
      mat.define-typography-level(
        22px,
        28px,
        700,
        $font-family: $header-font,
        $letter-spacing: 0.2em
      ),
    toolbar-subtitle: mat.define-typography-level(18px, 28px, 400, $letter-spacing: 0.1em),
    // Form Typography
    form-heading: mat.define-typography-level(18px, 24px, 300),
    form-subheading: mat.define-typography-level(13px, 24px, 400),
    form-hint: mat.define-typography-level(12px, 20px, 400),
    // Code
    code: mat.define-typography-level(11px, 16px, 300, $font-family: $monospace-font)
  );

  @each $key, $level in $app-typography {
    @if map-get($level, font-family) == null {
      $new-level: map-merge(
        $level,
        (
          font-family: $font-family,
        )
      );
      $app-typography: map-merge(
        $app-typography,
        (
          $key: $new-level,
        )
      );
    }
  }

  @return map-merge(
    $app-typography,
    (
      font-family: $font-family,
    )
  );
}
