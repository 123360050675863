@import 'components/dialog-toolbar/dialog-toolbar.theme';
@import 'components/dialog-panel/dialog-panel.theme';
@import 'components/dialog-layout/dialog-layout.theme';

@mixin x-dashboard-dialog-theme($theme, $typography) {
  @include x-dialog-toolbar-theme($theme, $typography);
  @include x-dialog-panel-theme($theme, $typography);
  @include x-dialog-layout-theme($theme, $typography);

  .x-fullscreen-dialog-pane {
    .mat-dialog-container {
      border-radius: 0;
      padding: 0;
    }
  }

  .x-no-padding-dialog-pane {
    .mat-dialog-container {
      padding: 0;
    }
  }

  .x-side-address-map-dialog-pane {
    .mat-dialog-container {
      position: relative;
      padding-right: 370px;
    }

    x-address-coordinates-input {
      width: 350px;
      left: calc(100% - 350px);
      top: 0;
      position: absolute;
    }
  }
}
