@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-ad-form-theme($theme, $typography) {
  $colors: map.get($theme, 'colors');
  $warn: map-get($theme, 'warn');

  .x-ad-form {
    .media {
      flex-grow: 1;
    }

    .no-media {
      margin: 0 8px;
      background: #fafafa;
      line-height: 1.2;
      height: 300px;
      box-sizing: border-box;
      display: flex;
      flex-flow: column nowrap;
      gap: 8px;
      cursor: pointer;
      color: #aaa;
      text-align: center;

      fa-icon {
        font-size: 5rem;
        color: #ccc;
      }
    }

    .upload-button,
    .remove-button {
      min-width: 32px;
    }

    .placement-block {
      padding: 0 10px;
      box-sizing: border-box;

      &.has-error {
        background: mat.get-color-from-palette($warn, 50);
        color: mat.get-color-from-palette($warn, 500);
      }

      .placement-info {
        padding: 18px 12px;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        button {
          flex-shrink: 1;
          margin-right: auto;
        }
      }
    }
  }
}
