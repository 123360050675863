@use '@angular/material' as mat;

@mixin x-search-field-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);
  $divider-color: map-get($foreground, divider);
  $background: map-get($theme, 'background');

  .x-search-field {
    flex: 0 1 200px;
    width: auto;
    border: 1px solid mat.get-color-from-palette($primary, 100);
    position: relative;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 5px 10px;

    .ng-fa-icon {
      margin-right: 12px;
    }

    @include mat.typography-level($typography, body-1);
    .inner {
      width: 100%;
      @include mat.typography-level($typography, input);
      input {
        width: 100%;
        border: none;
        outline: none;
        @include mat.typography-level($typography, input);
        line-height: 18px;
      }
    }
  }
}
