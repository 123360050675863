@use '@angular/material' as mat;
@use 'sass:map';
@use 'sass:math';

@mixin x-material-overrides($theme, $typography) {
  $foreground: map.get($theme, foreground);
  $primary: map.get($theme, primary);

  /* Buttons */

  .mat-mdc-button-base {
    text-transform: uppercase;
    letter-spacing: 1px;
    &.mat-mdc-raised-button {
      border-radius: 0;
    }

    &.mat-mdc-unelevated-button {
      border-radius: 0;
    }
  }

  mat-slide-toggle .mat-internal-form-field {
    line-height: 25px !important;
  }

  .mdc-icon-button {
    $icon-space: 24px;
    $icon-size: 16px;
    svg.svg-inline--fa {
      width: $icon-size;
      height: $icon-size;
      display: block;
      padding: math.div($icon-space - $icon-size, 2);
    }
    .ng-fa-icon {
      display: block;
      width: $icon-space;
      height: $icon-space;
    }
  }

  /** Card */

  .mat-mdc-card {
    border-radius: 0 !important;
    box-shadow: none !important;
  }

  .mat-mdc-dialog-surface {
    border-radius: 0 !important;
  }

  /** Tabs */
  .mat-mdc-tab-body-wrapper {
    height: 100%;
  }
  .mat-mdc-tab.mdc-tab {
    height: 52px !important;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: unset;
  }

  /** Tooltip */
  .mat-mdc-tooltip {
    white-space: pre-line;
  }

  /** Autocomplete */
  .mdc-list-item {
    &__primary-text {
      width: 100%;
      display: block;
    }
  }
}
