@use '@angular/material' as mat;
@mixin x-dashboard-layout-simplex-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, 'background');
  $divider-color: mat.get-color-from-palette($foreground, divider);
  $background-color: mat.get-color-from-palette($background, card);

  .x-dashboard-layout-simplex {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    max-height: 100%;
    overflow: hidden;

    background-color: $background-color;

    .x-dashboard-toolbar {
      flex: 0 0 54px;
    }

    .dashboard-panel-container {
      flex: 1 1 auto;
      display: flex;
      flex-flow: row nowrap;
      overflow: hidden;

      .x-dashboard-panel {
        border-right: 1px solid $divider-color;
      }

      .mat-tab-group {
        flex: 1 1 auto;
      }

      .mat-tab-body-wrapper {
        flex: 1 1 auto;
      }

      .x-dashboard-panel:last-child {
        border-right: none;
      }
    }
  }
}
