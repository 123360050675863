@use '@angular/material' as mat;
@mixin x-dashboard-media-theme($theme, $typography) {
  .x-media-dropbox {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
    padding: 24px;
    z-index: 1;
    @include mat.typography-level($typography, caption);

    &.dragging {
      background-color: rgba(0, 0, 0, 0.3);

      .mat-button-base {
        pointer-events: none;
      }
    }

    .ng-fa-icon {
      pointer-events: none;
    }
  }

  .x-media-url-dialog {
    .url-field {
      .mat-form-field-prefix {
        width: 100px;
      }
      .mat-form-field-infix {
        width: 200px;
      }
    }
  }

  .x-media-preview-panel {
    .mat-dialog-container {
      padding: 0;
      overflow: hidden;
    }
  }
}
