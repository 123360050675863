@use '@angular/material' as mat;
@mixin x-address-index-display-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $panel-background: mat.get-color-from-palette($background, background);

  .address-index-overlay {
    border-radius: 4px;
    background-color: $panel-background;
    padding-top: 0;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
      0 9px 46px 8px rgb(0 0 0 / 12%);
  }
}
