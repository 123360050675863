@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-order-tag-pill-theme($theme, $typography) {
  $colors: map.get($theme, 'colors');
  $foreground: map.get($theme, 'foreground');
  $background: map.get($theme, 'background');
  $colors: map.get($theme, 'colors');

  $size: 22px;

  .x-order-tag-pill {
    display: inline-block;
    width: $size;
    height: $size;
    border-radius: calc($size / 2);
    border: 1px solid mat.get-color-from-palette($foreground, 'divider');
    background-color: mat.get-color-from-palette($background, 'card');
    font-size: 12px;
    line-height: $size;
    text-align: center;

    @each $color, $color-theme in $colors {
      &.color-#{$color} {
        background-color: mat.get-color-from-palette($color-theme, 500);
        color: mat.get-color-from-palette($color-theme, '500-contrast');
      }
    }

    .ng-fa-icon {
      margin: 0px;
    }
  }
}
