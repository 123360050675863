@use '@angular/material' as mat;
@mixin xd-list-theme($theme, $typography) {
  $warn: map-get($theme, warn);

  .xd-list-item {
    overflow: hidden;

    .list-item-icon {
      flex: 0 0 48px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 12px 0;
      line-height: 20px;
    }

    .list-item-body {
      display: flex;
      flex-flow: column nowrap;
      padding: 12px 0;
      flex: 1 1 auto;
      overflow: hidden;

      [xdLine] {
        display: block;
        padding: 0;
        margin: 0;
        @include mat.typography-level($typography, 'body-2');

        &[preserveWhitespace] {
          white-space: pre-line;
        }
      }
      [xdHeadline] {
        display: block;
        padding: 0;
        margin: 0;
        @include mat.typography-level($typography, 'body-1');
        line-height: 20px;
      }
    }

    .list-item-meta {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      position: relative;
    }

    &.warning {
      background-color: mat.get-color-from-palette($warn, 50);
      color: mat.get-color-from-palette($warn, 500);

      [xdHeadline],
      .list-item-icon {
        color: mat.get-color-from-palette($warn, 400);
      }
    }
  }
}
