@use '@angular/material' as mat;

@mixin x-avatar-theme($theme, $typography) {
  $background: map-get($theme, background);

  $default-background: mat.get-color-from-palette($background, mat-selected-button);

  .x-acronym-avatar {
    display: inline-block;
    font-size: inherit;
    vertical-align: middle;

    svg {
      font-size: inherit;
      vertical-align: top;
      circle {
        fill: $default-background;
      }
      text {
        font-size: 1.5rem;
        font-weight: 900;
        font-family: mat.font-family($typography);
        text-transform: uppercase;
      }
    }

    svg.size-xs {
      width: 1em;
      height: 1em;
    }
    svg.size-sm {
      width: 2em;
      height: 2em;
    }
    svg.size-md {
      width: 3em;
      height: 3em;
    }
    svg.size-lg {
      width: 4em;
      height: 4em;
    }
  }
}
