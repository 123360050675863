@use '@angular/material' as mat;
@mixin x-card-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);

  $space: 16px 12px;

  .xd-card {
    &.raised {
      & > .card {
        @include mat.elevation(1);
      }
    }

    &.outlined {
      & > .card {
        border: 1px solid mat.get-color-from-palette($foreground, 'divider');
      }
    }

    &.none {
      & > .card {
        margin: 0;
      }
    }

    & > .card {
      width: 100%;
      border-radius: 2px;
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      margin: $space;
      margin-bottom: 0;
      @extend .mat-typography;

      & > {
        .card-header {
          border-bottom: 1px solid mat.get-color-from-palette($foreground, 'divider');
          @include mat.typography-level($typography, body-1);
          padding: $space;

          &:empty {
            display: none;
          }

          [xdHeader] {
            display: flex;
            flex-flow: row nowrap;
            gap: 8px;
            align-items: center;
          }

          [xdHeaderActions] {
            position: relative;
            margin: -14px 0 -14px auto;
          }
        }

        .card-body {
          padding: $space;

          &:empty {
            display: none;
          }
        }

        .card-footer {
          &:empty {
            display: none;
          }
        }

        .card-title + .card-body {
          padding-top: 0;
        }
      }
    }
  }
}
