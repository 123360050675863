@use '@angular/material' as mat;
@mixin x-product-form-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, 'foreground');
  $background: map-get($theme, 'background');
  $gray: map-get(map-get($theme, colors), 'gray');
  $warn: map-get($theme, 'warn');

  .x-product-form-base {
    .header-field {
      width: 100%;
    }

    .product-name-input {
      font-size: 18px;
    }

    .main-media-thumbnail {
      max-width: 100%;
    }

    .content-translation-panel {
      background-color: white;
      // box-sizing: border-box;
      @include mat.elevation(2);
      border-radius: 6px;
      padding: 24px;

      .mat-mdc-form-field {
        margin-bottom: 12px;
      }
      .mat-mdc-slide-toggle {
        margin-bottom: 12px;
      }
    }

    .media-list-heading {
      @include mat.typography-level($typography, 'body-2');
      color: mat.get-color-from-palette($foreground, 'secondary-text');
      border: 1px solid mat.get-color-from-palette($foreground, 'divider');
      padding: 16px;
      text-align: center;
    }

    .media-list {
      margin: 0 auto;
      max-width: 600px;
      padding: 16px;
      box-sizing: border-box;

      x-media-dropbox {
        position: relative;
        box-sizing: border-box;
        overflow: hidden;
      }

      .media-list-item {
        margin: 16px 0;
        padding: 16px;
        background-color: white;
        border: 1px solid mat.get-color-from-palette($foreground, 'divider');
        box-sizing: border-box;
        height: auto;
        display: flex;
        position: relative;

        x-media-thumbnail {
          flex-shrink: 0;
          width: 200px;
          height: 200px;
          margin-right: 16px;
        }

        .media-item-drag-handle {
          position: absolute;
          top: 16px;
          right: 16px;
        }

        .media-list-item-info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;

          & > p {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            align-items: center;
            margin: 0 0 6px 0;
            @include mat.typography-level($typography, 'body-2');
            fa-icon {
              margin-right: 16px;
              flex-grow: 0;
            }
          }

          .media-list-item-info-url {
            overflow-wrap: anywhere;
            font-size: 12px;
            color: mat.get-color-from-palette($gray, 900);
            text-decoration: none;
          }

          .media-list-item-info-actions {
            margin-top: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}
