@use '@angular/material' as mat;
@mixin x-taxon-index-theme($theme, $typography) {
  $primary: map-get($theme, 'primary');
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');

  .x-taxon-index {
    .x-taxon-tree {
      height: 100%;
    }

    .x-action-row {
      &.x-dragover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    .taxon-node-item {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 20px;
      flex: 1 1 auto;

      border-radius: 20px;
      font-size: 13px;
      cursor: pointer;

      .taxon-title {
        flex: 1 1 auto;
        font-weight: 500;
        font-size: 14px;
      }

      &:hover {
        background-color: mat.get-color-from-palette($background, 'hover');
      }

      &.selected {
        background-color: mat.get-color-from-palette($primary, 400);
        color: mat.get-color-from-palette($primary, '400-contrast');
      }

      &.x-dragging {
        opacity: 0.5;
      }

      &.x-dragover {
        background-color: rgba(0, 0, 0, 0.2);
      }

      &.x-dropblocked {
        opacity: 0.5;
      }
    }

    .taxon-actions {
      pointer-events: none;
      display: none;
    }

    .taxon-node-parent:hover .taxon-actions {
      pointer-events: auto;
      display: block;
    }

    .taxon-dropzone {
      min-width: 60px;
      min-height: 60px;
      display: block;
    }

    .taxon-dropzone.x-dropopen {
      background-color: mat.get-color-from-palette($background, 'hover');
    }

    .product-filters {
      display: flex;
      gap: 15px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      mat-form-field {
        .mat-mdc-form-field-subscript-wrapper {
          display: none;
        }
      }
    }

    .taxon-detail-action-row {
      justify-content: space-between;
    }

    x-taxon-product-table.hidden {
      display: none;
    }
  }
}
