@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-georegion-index-theme($theme, $typography) {
  $colors: map.get($theme, 'colors');

  $primary: map-get($theme, primary);
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');

  .x-georegion-index {
    .map-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 500px;
      padding: 12px;
      z-index: 10;
      overflow: hidden;
    }

    .region-control {
      width: 220px;
    }

    .map-info-window {
      .mat-mdc-card-content {
        max-height: 200px;
        overflow-y: auto;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
      .mat-mdc-list[dense] .mat-mdc-list-item .mat-list-item-content {
        padding: 12px 0;
      }
    }

    .definition-viewport {
      width: 100%;
      height: 100%;

      .definition-item {
        display: flex;
        flex-direction: row;
        padding: 3px 6px;
        height: 30px;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid map-get($foreground, 'divider');

        &:hover {
          background-color: map-get($background, hover);
        }

        &.selected {
          color: mat.get-color-from-palette($primary, 500);
          font-weight: bold;
        }

        &.excluded {
          color: mat.get-color-from-palette(map.get($colors, 'red'));
        }

        &-icon {
          margin-right: 6px;
        }
        &-label {
          flex: content;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 390px;
          cursor: pointer;
        }
        &-action {
          padding-left: 6px;
          margin-left: auto;
        }
      }
    }

    .region-tree {
      width: 100%;
      .region-tree-item {
        &.active {
          color: mat.get-color-from-palette($primary, 500);
        }

        a {
          flex-grow: 1;
          display: block;
          padding: 3px 9px;
          &:hover {
            cursor: pointer;
            background-color: map-get($background, hover);
          }
        }
      }
      .region-sub-tree {
        padding-left: 0;
        margin-left: 12px;
        // a {
        //   border-left: 1px solid rgba(0, 0, 0, 0.1);
        // }
      }
    }

    .address-search-field-inner {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      justify-content: stretch;
      x-address-autocomplete {
        flex-grow: 1;
      }
    }
  }
}
