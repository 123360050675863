@use '@angular/material' as mat;

@mixin x-data-select-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);

  .x-data-select {
    .mat-select {
      width: 200px;
      flex: 1 1 auto;
    }

    .mat-select-trigger {
      height: unset;
      min-height: 1.125em;
      .mat-select-value-text {
        mat-select-trigger {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          gap: 6px 6px;
          width: 100%;
          white-space: normal;
        }
      }
    }

    span {
      white-space: normal;
    }

    .default-trigger {
      display: inline-block;
      padding: 2px 6px;
      background-color: white;
      border: 1px solid mat.get-color-from-palette($foreground, divider);
      border-radius: 6px;
    }
  }

  .x-data-select-panel.mat-select-panel {
    .mat-option {
      // line-height: 2em;
      // height: 2em;

      .mat-option-text {
        display: inline-flex;
        align-items: center;
      }
    }
  }
}
