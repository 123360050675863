@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-member-pill-theme($theme, $typography) {
  $colors: map.get($theme, 'colors');

  .x-member-pill {
    &.state-ACTIVE-ACTIVE {
      background-color: mat.get-color-from-palette(map.get($colors, 'green'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'green'), '500-contrast');
    }
    &.state-ACTIVE-PENDING {
      background-color: mat.get-color-from-palette(map.get($colors, 'yellow'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'yellow'), '500-contrast');
    }
    &.state-ACTIVE-EXPIRED {
      background-color: mat.get-color-from-palette(map.get($colors, 'red'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'red'), '500-contrast');
    }
    &.state-INACTIVE-ACTIVE {
      background-color: mat.get-color-from-palette(map.get($colors, 'red'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'red'), '500-contrast');
    }
    &.state-INACTIVE-PENDING {
      background-color: mat.get-color-from-palette(map.get($colors, 'red'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'red'), '500-contrast');
    }
    &.state-INACTIVE-EXPIRED {
      background-color: mat.get-color-from-palette(map.get($colors, 'red'), 500);
      color: mat.get-color-from-palette(map.get($colors, 'red'), '500-contrast');
    }
  }
}
