@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-browser-overrides($theme, $typography) {
  $foreground: map.get($theme, foreground);

  html {
    font-size: 12px;
  }

  html,
  body {
    height: 100%;
  }
  body {
    margin: 0;
    font-family: mat.font-family($typography);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  @supports (scrollbar-width: auto) {
    * {
      scrollbar-color: map.get($foreground, scrollbar-thumb) map.get($foreground, scrollbar-track);
    }
  }

  @supports selector(::-webkit-scrollbar) {
    *::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }

    /* Track */
    *::-webkit-scrollbar-track {
      background: map.get($foreground, scrollbar-track);
    }

    /* Handle */
    *::-webkit-scrollbar-thumb {
      background: map.get($foreground, scrollbar-thumb);
    }

    /* Handle on hover */
    *::-webkit-scrollbar-thumb:hover {
      background: map.get($foreground, scrollbar-thumb-hover);
    }
  }

  table {
    width: 100%;
    @include mat.typography-level($typography, 'body-2');
    border-spacing: 0;
    tbody {
      vertical-align: text-top;
    }
    th {
      text-align: left;
      @include mat.typography-level($typography, 'body-1');
    }
    td {
      @include mat.typography-level($typography, 'body-2');
    }
  }
}
