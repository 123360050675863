@use '@angular/material' as mat;
@mixin x-product-detail-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, 'background');
  $gray: map-get(map-get($theme, colors), gray);
  $warn: map-get($theme, 'warn');

  x-product-detail {
    .product-translation {
      &-name {
        margin-bottom: 12px;
      }
    }

    .product-attribute {
      &-value {
        &-name {
          margin-bottom: 6px;
        }
        &-value {
        }
      }
    }

    .main-media-thumbnail {
      height: auto;
      max-width: 100%;
    }

    .media-grid {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;

      &-item {
        margin: 6px;
        padding: 12px;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;
        background: white;
        @include mat.elevation(1);
        border-radius: 4px;
        cursor: zoom-in;

        x-media-thumbnail {
          display: block;
        }

        &-overlay {
          position: absolute;
          right: 12px;
          top: 18px;
          opacity: 0.8;
          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .variants-table {
      width: 100%;
      background: white;

      th,
      td {
        padding: 12px 16px;
        white-space: nowrap;
      }

      .available-regions {
        & > span:not(:last-child)::after {
          content: ', ';
        }
      }
    }
  }

  .taxon-pill {
    cursor: pointer;
  }
}
