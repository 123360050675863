@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-ai-chat-theme($theme, $typography) {
  $colors: map-get($theme, 'colors');
  $primary: map-get($theme, 'primary');
  $accent: map-get($theme, 'accent');

  $chat-max-width: 100%;
  $chat-spacer: 0.5rem;

  .x-ai-chat {
    display: flex;
    flex-direction: column;
    width: $chat-max-width;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;

    header {
      display: flex;
      user-select: none;

      padding-bottom: $chat-spacer;
      justify-content: space-between;
      align-items: center;

      h2 {
        font-weight: 300;
      }

      .actions {
        display: flex;
        gap: 5px;
      }
    }
    .chat-action {
      padding: 5px 10px;
      cursor: pointer;
      min-width: unset;
    }

    .message-list {
      display: flex;
      flex-direction: column;

      padding-top: $chat-spacer;
      border-top: 1px solid map-get($primary, 50);

      height: 100%;
      width: 100%;
      overflow-y: auto;

      .chat-spacer {
        flex-grow: 1;
      }

      .message {
        width: fit-content;
        max-width: $chat-max-width;

        display: flex;
        gap: 4px;

        padding: 0.5rem;
        padding-bottom: 0px;
        margin-bottom: $chat-spacer;

        border-radius: 0.4em;
        background-color: map-get($accent, 200);

        font-size: small;

        &.user {
          padding-left: 0.7rem;
          padding-right: 0.7rem;
          background-color: map-get($accent, 100);
          align-self: flex-end;
        }

        .x-markdown-view {
          max-width: 550px;
          text-wrap: pretty;
          p {
            margin-top: 0px;
          }
          a {
            background-color: map-get($primary, 400);
            padding: 2px 4px;
            color: white;
            border-radius: 0.25em;
          }
        }
      }

      .loader {
        align-self: center;
      }

      .empty-message {
        display: flex;
        justify-content: center;
        height: 100%;
        text-align: center;
        align-items: center;
        padding: 0.5rem;
        margin-bottom: $chat-spacer;
      }

      /* Width and background of the scrollbar */
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Style for the scrollbar thumb (the draggable part) */
      &::-webkit-scrollbar-thumb {
        background-color: map-get($accent, 300);
      }

      /* Style for the scrollbar thumb when hovered */
      &::-webkit-scrollbar-thumb:hover {
        background-color: map-get($accent, 500);
      }
    }

    .text-actions {
      display: flex;
      gap: 2px;
      margin-top: $chat-spacer;

      .mat-mdc-input-element {
        display: flex;
        flex-grow: 1;
        border: 1px solid map-get($primary, 50);
        border-radius: 0.2em;
        box-shadow: none;
        padding: 5px;
        resize: none;
      }

      .mat-mdc-input-element:focus-visible {
        outline: none;
        border: 1px solid map-get($primary, 300);
      }
    }
  }
}
