@use '@angular/material' as mat;

@mixin x-dashboard-errors-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $warn: map-get($theme, warn);

  .x-dashboard-errors {
    display: block;
    background-color: mat.get-color-from-palette($warn, 500);
    color: mat.get-color-from-palette($warn, '500-contrast');
    border-radius: 4px;
    padding: 12px;

    .panel-error {
      position: relative;
      display: flex;
      align-items: center;
      flex-flow: row nowrap;

      &-icon {
        margin-right: 12px;
        margin-top: 4px;
        align-self: flex-start;
      }
      &-title {
        @include mat.typography-level($typography, body-2);
      }
      &-message {
        @include mat.typography-level($typography, body-1);
      }

      &-detail-toggle {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
      }

      &-code {
        @include mat.typography-level($typography, code);
      }

      &-detail {
        @include mat.typography-level($typography, code);
        padding: 12px;
        white-space: pre-wrap;
        overflow: hidden;
        word-break: break-all;
        background-color: mat.get-color-from-palette($background, card);
        color: mat.get-color-from-palette($foreground, text);
      }
    }
  }
}
