@use '@angular/material' as mat;

@import './components/current-user/current-user.theme';

@mixin x-auth-theme($theme, $typography) {
  @include x-current-user-theme($theme, $typography);

  $primary: map-get($theme, 'primary');
  $warn: map-get($theme, 'warn');

  .x-auth-layout {
    background-color: mat.get-color-from-palette($primary, 500);
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .x-auth-base {
    gap: 32px;
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
    mat-card {
      width: 300px;
      /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
      /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
      mat-card-content {
        font-size: 14px;
        .mat-mdc-form-field {
          width: 100%;
          margin-bottom: 12px;

          // .field-button {
          //   bottom: 6px;
          // }
        }

        .mat-mdc-form-field:last-of-type {
          margin-bottom: 0;
        }
      }
      /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
      mat-card-title {
        text-align: center;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
      mat-card-subtitle {
        text-align: center;
      }
    }

    .brand {
      margin-top: -96px;
      color: white;
      fill: white;
      text-align: center;

      .brand-img {
      }

      .brand-name {
        letter-spacing: 0.15em;
        color: white;
        text-transform: uppercase;
        padding: 24px;
        @include mat.typography-level($typography, headline-4);
      }
    }
  }
}
