@use '@angular/material' as mat;

@mixin x-dialog-toolbar-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);

  $divider-color: mat.get-color-from-palette($foreground, divider);
  $title-color: mat.get-color-from-palette($primary, 500);

  .x-dialog-toolbar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    height: 64px;
    border-bottom: 2px solid $divider-color;

    .toolbar-icon {
      margin-right: 12px;
      color: $title-color;
    }

    .toolbar-header-divider {
      height: 40px;
      width: 12px;
      margin-right: 24px;
      border-right: 1px solid $divider-color;
    }

    .toolbar-header {
      color: $title-color;
      margin: 0 12px 0 0;
      @include mat.typography-level($typography, toolbar-title);
    }

    .toolbar-subheader {
      margin: 0 12px 0 0;
      @include mat.typography-level($typography, toolbar-subtitle);
    }

    .toolbar-header-spacer {
      flex: 1 0 auto;
    }

    [xToolbarAction] {
      margin-left: 12px;
    }

    [xToolbarFlex] {
      flex: 1 1 auto;
    }

    .mat-mdc-select {
      width: 150px;
      margin-left: 6px;
      margin-right: 6px;
    }
    .mat-mdc-select + .mat-select {
      margin-left: 12px;
    }
  }
}
