@use '@angular/material' as mat;

@mixin x-taxon-tree-theme($theme, $typography) {
  $background: map-get($theme, 'background');

  .x-taxon-tree {
    display: flex;
    flex-flow: column nowrap;
    overflow: visible;
    background-color: mat.get-color-from-palette($background, 'card');

    .taxon-node {
      display: flex;
      flex-flow: column nowrap;
      user-select: none;

      &-parent {
        border-radius: 20px;
        height: 40px;
        display: flex;
        align-items: center;
      }

      &-children {
        padding-left: 12px;
        &.hidden {
          display: none;
        }
      }

      &-position-handle {
        width: 40px;
        height: 40px;
        color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
