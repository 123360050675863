@use '@angular/material' as mat;
@mixin x-dashboard-panel-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);

  $header-footer-background: mat.get-color-from-palette($background, card);
  $panel-background: mat.get-color-from-palette($background, card);
  $side-panel-background: mat.get-color-from-palette($background, status-bar);

  $divider-color: mat.get-color-from-palette($foreground, divider);
  $title-color: mat.get-color-from-palette($primary, 500);

  .x-dashboard-panel {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;

    overflow: auto;

    background-color: $panel-background;

    &.bg-transparent {
      background: transparent;
      .panel-content {
        background: transparent;
      }
    }

    &.side {
      flex: 0 1 270px;
      .panel-content {
        background-color: $side-panel-background;
      }
    }

    &.side-x {
      flex: 0 1 200px;
      .panel-content {
        background-color: $side-panel-background;
      }
    }

    &.side-m {
      flex: 0 1 400px;
      .panel-content {
        background-color: $side-panel-background;
      }
    }

    &.side-l {
      flex: 0 1 500px;
      .panel-content {
        background-color: $side-panel-background;
      }
    }

    &.half {
      flex: 0 1 50%;
    }

    &.full {
      flex: 1 1 700px;
    }

    &.double {
      flex: 1 1 1400px;
    }

    .panel-header {
      display: flex;
      flex-flow: column nowrap;
      background-color: $header-footer-background;

      &:not(:empty) {
        border-bottom: 1px solid $divider-color;
      }

      [xPanelHeader],
      .x-panel-header {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        padding: 6px 12px;
        min-height: 39px;
        margin-top: 0;
        margin-bottom: 0;
        @include mat.typography-level($typography, 'body-1');
      }

      [xPanelTitle] {
        @include mat.typography-level($typography, 'body-2');
        margin: 0;
        flex: 1 1 auto;
      }
    }

    .panel-footer {
      display: flex;
      flex-flow: column nowrap;
      background-color: $header-footer-background;

      &:not(:empty) {
        border-top: 1px solid $divider-color;
      }

      [xPanelFooter] {
      }
    }

    .panel-content {
      position: relative;
      background-color: $panel-background;
      flex: 1 1 auto;
      overflow: auto;

      min-height: 120px;

      .mat-tab-header {
        background-color: $header-footer-background;
      }

      [xPanelTab] {
        height: 100%;
        .mat-tab-label {
          min-width: 60px;
          height: 55px;
        }
      }
    }

    .panel-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    .panel-loader-overlay {
      pointer-events: none;
      background-color: rgba($panel-background, 0.5);
      bottom: unset;
      height: 2px;
      .mat-progress-bar {
        height: 2px;
      }
    }

    .panel-error-overlay {
      background-color: $panel-background;
    }

    // https://stackoverflow.com/questions/33454533/cant-scroll-to-top-of-flex-item-that-is-overflowing-container
    .panel-scroll {
      max-height: 100%;
      overflow: auto;
    }

    .panel-error {
      padding: 12px;
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      &-icon {
        margin-right: 12px;
        margin-top: 4px;
        align-self: flex-start;
      }
      &-title {
        @include mat.typography-level($typography, body-2);
      }
      &-message {
        @include mat.typography-level($typography, body-1);
      }

      &-detail-toggle {
        @include mat.typography-level($typography, body-1);
        color: mat.get-color-from-palette($foreground, 'secondary-text');
        text-decoration: underline;
        cursor: pointer;
      }

      &-code {
        @include mat.typography-level($typography, code);
      }

      &-detail {
        @include mat.typography-level($typography, code);
        padding: 12px;
        white-space: pre-wrap;
        overflow: hidden;
        word-break: break-all;
        background-color: mat.get-color-from-palette($background, card);
      }
    }

    .panel-close-button-insert {
      position: absolute;
      bottom: 8px;
      right: 0;
      height: 40px;
      width: 40px;
      background-color: mat.get-color-from-palette($background, card);
      // border-radius: 20px 0 0 20px;
      border: 1px solid $divider-color;
      border-right: none;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      cursor: pointer;
      &:hover {
        background-color: mat.get-color-from-palette($background, hover);
      }
    }

    &.closed {
      flex: 0 0 24px;

      .panel-header {
        display: none;
      }
      .panel-content {
        display: none;
      }
      .panel-footer {
        display: none;
      }
      .panel-close-button-insert {
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 0 0 0 0;
        border: none;
        .ng-fa-icon {
          transform: rotate(180deg);
        }
      }
    }

    &.closeable {
      .panel-footer {
        padding-right: 40px;
      }
    }

    // nested panels
    .x-dashboard-panel {
      .panel-header {
        &:not(:empty) {
          border-top: 1px solid $divider-color;
        }
      }
    }
  }
}
