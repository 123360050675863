@use '@angular/material' as mat;

@mixin x-colors($theme, $typography) {
  @each $color, $palette in map-get($theme, colors) {
    .#{'x-color-' + $color} {
      --x-color: var(--x-#{$color}-default);
      --x-color-contrast: var(--x-#{$color}-default-contrast);
    }
    .x-color-primary {
      --x-color: var(--x-primary-default);
      --x-color-contrast: var(--x-primary-default-contrast);
    }
    .x-color-accent {
      --x-color: var(--x-accent-default);
      --x-color-contrast: var(--x-accent-default-contrast);
    }
    .x-color-warn {
      --x-color: var(--x-warn-default);
      --x-color-contrast: var(--x-warn-default-contrast);
    }
  }
}
