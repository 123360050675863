@use '@angular/material' as mat;
@mixin x-dashboard-menu-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, 'background');

  $divider-color: mat.get-color-from-palette($primary, 300);
  $background-color: mat.get-color-from-palette($primary, 0); //50

  $item-color: mat.get-color-from-palette($primary, 800);
  $item-color-active: mat.get-color-from-palette($primary, 700);

  $icon-color: mat.get-color-from-palette($primary, 800);
  $icon-color-hover: mat.get-color-from-palette($primary, 600);
  $icon-color-active: mat.get-color-from-palette($primary, 700);

  $item-background: transparent;
  $item-background-hover: mat.get-color-from-palette($background, hover);
  $item-background-active: mat.get-color-from-palette($primary, 50);

  .x-menu {
    background-color: $background-color;
    padding-bottom: 48px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    .cdk-tree-node {
      display: block;
    }

    .menu-item {
      display: flex;
      align-items: center;
      padding: 0 16px;

      text-decoration: none;
      color: $item-color;
      height: 54px;
      background-color: $item-background;
      outline: none;
      box-sizing: border-box;
      border: none;
      cursor: pointer;
      transition:
        height 100ms ease-in,
        background-color 200ms ease-in;
      font: 600 15px/24px mat.font-family($typography);
      letter-spacing: 0.08em;

      .item-icon {
        margin-right: 12px;
        width: 24px;
        color: $icon-color;
        text-align: center;
        flex: 0 0 auto;
        opacity: 0.3;
      }

      .item-star {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        bottom: 0;
        justify-content: center;
        align-items: center;
      }

      .item-title {
        flex: 1 1 auto;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &-children {
        padding: 0 0 12px 0;
      }

      &-children .child {
        padding: 0 12px 0 12px;
        margin-left: 40px;
        height: 36px;
        font: 600 14px/24px mat.font-family($typography);
        letter-spacing: 0.05em;
        &:hover {
          padding-right: 42px;
        }
      }

      &:hover {
        background-color: $item-background-hover;

        .item-icon {
          color: $icon-color-hover;
          opacity: 1;
        }
        .item-star {
          display: flex;
        }
      }

      &.item-active {
        background-color: $item-background-active;
        color: $item-color-active;
        .item-icon {
          color: $icon-color-active;
          opacity: 1;
        }
        &:hover {
          .item-icon {
            color: $icon-color-active;
          }
        }
      }

      &.expanded {
        height: 54px;
      }

      &.hidden {
        opacity: 0.3;
      }
    }

    .menu-settings {
      position: absolute;
      bottom: 6px;
      right: 0;
      border: 1px solid mat.get-color-from-palette($foreground, divider);
      background-color: mat.get-color-from-palette($background, card);
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: mat.get-color-from-palette($foreground, scrollbar-track);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: mat.get-color-from-palette($foreground, scrollbar-thumb);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: mat.get-color-from-palette($foreground, scrollbar-thumb-hover);
    }
  }
}
